import {getConfigApi} from "../api/config";
import store from '../store'


export async function getJsConfig(config) {
    wx.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: config.corpid, // 必填，企业微信的corpID
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.noncestr, // 必填，生成签名的随机串
        signature: config.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: config.jsApiList,
        
    });

}

export async function wxConfig(config) {
    return new Promise((resolve, reject) => {
        wx.config(config)
        wx.ready(resolve)
        wx.error(reject)
    }).then(() => {
        // WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
    }, error => {
        console.error('wx.error', error)
        throw error
    })
}

export async function wxAgentConfig(config) {
    return new Promise((success, fail) => {
        wx.agentConfig({...config, success, fail})
    }).then(res => {
        console.log('wx.agentConfig success', res)
        store.commit('setVxState', true)
        return res
    }, error => {
        console.error('wx.agentConfig fail', error)
        throw error
    })
}


export function ready() {
    console.log('ready开始')
    return new Promise((resolve) => {

        wx.ready(async () => {
            const {code, data} = await getConfigApi({
                type: 2,
                current_url: window.location.origin + window.location.pathname
            })
            if (code === 200) {
                wx.agentConfig({
                    corpid: data.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                    agentid: data.agentid, // 必填，企业微信的应用id
                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.noncestr, // 必填，生成签名的随机串
                    signature: data.signature,// 必填，签名
                    jsApiList: ['WWOpenData'], //必填
                    success: () => {
                        console.log('结束')
                        resolve()
                    }
                })


            }
        })

    })


}

export function otherBrowser() {
    getConfigApi({
        type: 2,
        current_url: window.location.origin + window.location.pathname
    }).then(res => {
        if (res.code === 200) {
            const data = res.data;
            wx.agentConfig({
                corpid: data.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: data.agentid, // 必填，企业微信的应用id
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.noncestr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: ['WWOpenData'], //必填
                success: () => {
                }
            })
        }
    })
}
