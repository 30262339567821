import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        vxState: false,
        listData:{
            index: true,
            diy_workbench:true,
            data_source_mannger: true,
            look_organizational: true,
            application_mannger: true,
            role_auth_set: true,
            setting_center:false,
            edit_template:false,
            use_template:false,
            del_template:false,
            add_template:false,
            add_datasource: false,
            edit_datasource: false,
            approval_mannger: false,
            del_datasource: false,
            del_application: false,
            edit_application: false,
            add_application: false,
            import_user_relation: false,
            synchronization_organizational: false,
            edit_user:false,
            add_role:false,
            edit_role: false,
            del_role: false,
            look_authority:false,
        },
        modelList:[],
        kfurl:'',//客服二维码
        isExpire:0,
    },
    mutations: {
        setKF(state,url){
            state.kfurl=url
        },
        setVxState(state,vxState) {
            state.vxState =vxState
        },
        InitListData(state,data){
            if(data=='all'){
                for(let item in state.listData ){
                    state.listData[item]=true
                }
            }else{
                state.listData.index=false;
                state.listData.role_auth_set=false;
                state.listData.data_source_mannger=false;
                state.listData.look_organizational=false;
                state.listData.application_mannger=false;
                state.listData.diy_workbench=false;
                for(let item in state.listData){
                    data.map(auth=>{
                        state.listData[auth]=true
                    })
                }
            }
        },
        getExpireMsg(state,data){
            state.isExpire=data
        },
        updateModel(state,data){
            state.modelList[data.pushIndex]=data.data
        },
    },
    actions: {
        InitListData(context,data){
            context.commit('InitListData',data)
        },
        getExpireMsg(context,data){
            context.commit('getExpireMsg',data)
        },
        updateModel(context,data){
            context.commit('updateModel',data)
        },
    },
    modules: {}
})
