<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@import "//at.alicdn.com/t/font_2271926_ox9vrg6e5l.css";
</style>
