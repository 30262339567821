import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import instance  from '@/utils/request'
import ElementUI from 'element-ui';
import Vant from 'vant' // Vant
import scroll from 'vue-seamless-scroll'

import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css'  // Vant-css
// 引入全局样式
import '@/assets/styles/common.less';

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(Vant)
Vue.use(scroll)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$axios = instance
Vue.prototype.wwOpenFlag = false;


//全局参数
window.eventBus = new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
