<template>
  <div class="home">
    <div class="home-header">
      <div class="center-con">
        <Header></Header>
      </div>
    </div>
    <div class="container">
      <div class="main-content">
        <!-- 主体页面路由 -->
        <router-view></router-view>
      </div>
    </div>
<!--    <div class="home-foot">-->
<!--      <Footer></Footer>-->
<!--    </div>-->

  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from '@/components/Footer'
export default {
  name: 'Home',
  components: {
    Header,Footer
  },
  created() {
    this.delToken()
  },
  methods:{
    delToken() {
      var reg = new RegExp("(^|&)" + "token" + "=([^&]*)(&|$)");
      if (window.location.search.substr(1).match(reg)) {
        //去掉地址栏token
        history.replaceState(
            null,
            null,
            location.origin + location.pathname + location.hash
        );
      }
    },
  }
}
</script>

<style lang="less" scoped>
.home{
  min-width: 960px;
    background: #EEEEEE;
  display: flex;
  flex-direction: column;
  height: 100%;
  .home-header{
    height:56px;
    width: 100%;
    background: #FDFEFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    .center-con{
      height: 56px;
      width: 99%;
      max-width: 1280px;
      margin:0 auto;
    }
  }
  .container{
    padding-top: 28px;
    width: 100%;
    background: #eee;
    .main-content{
      width: 100%;
      max-width: 1280px;
      min-height: calc(100vh - 200px);
      margin:0 auto;
      background: #fff;
    }
  }
  .home-foot{
    width: 100%;
    height: 74px;
  }

}
</style>
