<template>
  <div class="home-head flex-bet">
    <div class="home-headers flex-start">
      <div @click="goToHome" class="head-title flex-start">
        <img src="@/assets/images/LOGO.svg" alt="logo" class="head-logo">
      </div>
    </div>
    <div class="flex-bet right-item">
      <div class="menu-list flex-start">
        <router-link v-for="(item,index) in menuList" class="menu-item"  :key='index'
                     :to="{ path:'/home/'+item.route}" tag="a" v-show="item.noAuth">
          <i :class="item.icon"></i><span>{{item.name}}</span>
        </router-link>
      </div>
      <div class="settings flex-end" style="flex: 0 0 270px;display:flex;align-items:center;">
        <img src="@/assets/images/customer_service.svg" @click="showlink = true" style="margin-right:10px;" >
        <div class="right-setting" v-show="setting_center" @click="goToSet"><i class="iconfont icon-shezhi"></i>设置</div>
        <div class="flex-start middel-msg">
          <img src="@/assets/images/user.png" alt="user" class="avatar">
            <span class="user-name" v-if="allIsWx3=='true'"><wxComponent type="userName" :openid="userId"/></span>
            <span class="user-name user-name-zijian" v-else>{{username}}</span>
        </div>
        <el-dropdown trigger="click" v-show="isWeChat" @command="handleCommand">
          <i class="iconfont icon-gengduo"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 显示客服 -->
    <link-service :show.sync="showlink"></link-service>
  </div>

</template>

<script>
import {logOut} from '@/api/workbench';
import wxComponent from "@/components/wx";
import {getConfigApi} from "../api/config";
import {otherBrowser, wxAgentConfig, wxConfig} from "../utils/wx";
import LinkService from "@/components/linkService";
export default {
name: "Header",
  data(){
    return {
      menuList:[
        {name:'首页',route:'index',icon:'iconfont icon-home',noAuth:this.$store.state.listData.index,auth:'index'},
        {name:'自定义工作台',route:'workbench',noAuth:this.$store.state.listData.diy_workbench,auth:'diy_workbench'},
        {name:'数据源管理',route:'datamana',noAuth:this.$store.state.listData.data_source_mannger,auth:'data_source_mannger'},
        {name:'应用管理',route:'appmana',noAuth:this.$store.state.listData.application_mannger,auth:'application_mannger'},
        {name:'组织架构',route:'dept',noAuth:this.$store.state.listData.look_organizational,auth:'look_organizational'},
      ],
      menuIndex:-1,
      searchBox:false,
      searchText:'',
      isWeChat:false,
      userId:'',
      username:'',
      allIsWx3:true,
      showlink:false,//联系客服弹框显示
    }
  },
  components:{
    wxComponent,
    LinkService
  },

  created() {
    setTimeout(()=>{
      this.isUserAuth();
    },2100);
    (/MicroMessenger/i.test(navigator.userAgent)) ? this.isWeChat=false : this.isWeChat=true
  },
  computed:{
    setting_center(){
      return this.$store.state.listData.setting_center
    }
  },
  mounted() {
    if(process.env.NODE_ENV === 'production'){
      setTimeout(()=>{
        this.getUserName();
      },2000)
    }

  },
  methods:{
    async getUserName(){
      let {userId,username}=JSON.parse(sessionStorage.getItem('userInfo'))
      let is_wx3=sessionStorage.getItem('is_wx3');
      this.userId=userId;
      this.username=username;
      this.allIsWx3=is_wx3;
      if(is_wx3=='true'){
        if (/MicroMessenger/i.test(navigator.userAgent)) {
          let getConf={};
          await getConfigApi({
            type: 1,
            current_url: window.location.origin + window.location.pathname
          }).then(res=>{
            getConf=res.data;
          })
          const getConfig = {
            beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: getConf.corpid, // 必填，企业微信的corpID
            timestamp: getConf.timestamp, // 必填，生成签名的时间戳
            nonceStr: getConf.noncestr, // 必填，生成签名的随机串
            signature: getConf.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['WWOpenData',"selectEnterpriseContact","openEnterpriseChat"],
          }
          await wxConfig(getConfig)
          const {data} = await getConfigApi({type: 2, current_url: window.location.origin + window.location.pathname})
          const config = {
            beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            corpid: data.corpid, // 必填，企业微信的corpID
            agentid: data.agentid,
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['WWOpenData',"selectEnterpriseContact","openEnterpriseChat"],
              fail: function(res) {
                  console.log(res , "wx报错")
                  if(res.errMsg.indexOf('function not exist') > -1){
                      alert('版本过低请升级')
                  }
              } 
          }

          wxAgentConfig(config).then(_ => {
            WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
          })
        } else {
          otherBrowser()
        }
      }

    },
    isUserAuth(){
      const role_id=JSON.parse(sessionStorage.getItem('userInfo')).role
      if(role_id!=1){
        let authArr={};
        if(!sessionStorage.getItem('isHead')){
          authArr.index=this.$store.state.listData.index;
          authArr.diy_workbench=this.$store.state.listData.diy_workbench;
          authArr.data_source_mannger=this.$store.state.listData.data_source_mannger;
          authArr.application_mannger=this.$store.state.listData.application_mannger;
          authArr.look_organizational=this.$store.state.listData.look_organizational;

          this.menuList=[
            {name:'首页',route:'index',icon:'iconfont icon-home',noAuth:this.$store.state.listData.index,auth:'index'},
            {name:'自定义工作台',route:'workbench',noAuth:this.$store.state.listData.diy_workbench,auth:'diy_workbench'},
            {name:'数据源管理',route:'datamana',noAuth:this.$store.state.listData.data_source_mannger,auth:'data_source_mannger'},
            {name:'应用管理',route:'appmana',noAuth:this.$store.state.listData.application_mannger,auth:'application_mannger'},
            {name:'组织架构',route:'dept',noAuth:this.$store.state.listData.look_organizational,auth:'look_organizational'},
          ]
          sessionStorage.setItem('isHead',JSON.stringify(authArr))
          this.$forceUpdate()
        }else{
          const authList=JSON.parse(sessionStorage.getItem('isHead'))
          this.menuList=[
            {name:'首页',route:'index',icon:'iconfont icon-home',noAuth:authList.index,auth:'index'},
            {name:'自定义工作台',route:'workbench',noAuth:authList.diy_workbench,auth:'diy_workbench'},
            {name:'数据源管理',route:'datamana',noAuth:authList.data_source_mannger,auth:'data_source_mannger'},
            {name:'应用管理',route:'appmana',noAuth:authList.application_mannger,auth:'application_mannger'},
            {name:'组织架构',route:'dept',noAuth:authList.look_organizational,auth:'look_organizational'},
          ]
          this.$forceUpdate()
        }
      }

    },
    handleCommand(command){
      if(command==='a'){
        if(this.allIsWx3==='false'){
          sessionStorage.clear();
          this.$cookies.remove('userid');
          this.$cookies.remove('logintype');
          this.$router.replace('/login');
        }else{
          logOut().then(res=>{
            window.location.href=res.data.ScanCodeUrl
          })
        }
      }
    },

  goToHome(){
      this.$router.push('/home/index')
    },
    goToSet(){
      this.$router.push('/home/set/asynchronous')
    }

  }
}
</script>

<style lang="less" scoped>
.home-head{
  height: 100%;
  .home-headers{
    flex:0 0 220px;
    .head-title{

      //margin-right: 110px;
      .head-logo{
        height: 31px;
        cursor: pointer;
      }
      p{
        font-size: 18px;
        margin-left: 11px;
      }
    }
  }

  /deep/.el-menu {
    margin: 0 auto;
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    .el-menu-item {
      margin: 0 30px;
      padding: 0 !important;
    }
  }
  /deep/.el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .menu-list{
    min-width: 540px;
    .menu-item{
      display: flex;
      align-items: center;
      height: 56px;
      line-height: 56px;
      padding: 0 15px;
      i{
        font-size: 12px;
        margin-right: 8px;
      }
    }
    .router-link-active{
      position: relative;
      color:#fff;
      background: #0078F0;
    }
  }
  .right-item{
    flex:1;
    cursor: pointer;
    .settings{
      flex:0 0 243px;
    }
    .right-setting{
      font-size: 14px;
      //margin-right: 28px;
      .icon-shezhi{
        margin-right: 6px;
      }
    }
    .middel-msg{
      margin-left:18px;
    }
    .avatar{
      width: 24px;
      height: 24px;
      border-radius:50%;
    }
    .user-name{
      margin:0 8px;
    }
    .icon-gengduo{
      color:#ccc;
    }
  }
  .model-btns{
    text-align: center;
    font-size: 12px;
    div{
      line-height: 28px;
      height: 28px;
      border-radius: 2px;
      margin-left: 16px;
    }
    .cancel{
      width: 72px;
      border: 1px solid #CCCCCC;
    }
    .save{
      width: 96px;
      border: 1px solid #CCCCCC;
    }
    .use{
      width: 72px;
      background: #0082EF;
      color:#fff;
    }
  }

}


</style>