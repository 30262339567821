// axios封装
import axios from "axios";
// import router from '../router'
import {Message} from 'element-ui'
import store from '@/store'
import router from "../router";
// 2.配置

    const instance = axios.create({
        baseURL:  process.env.NODE_ENV === 'production' ? location.origin :'/api' ,      // 请求地址
        timeout: 15000,// 请求超时
    })


//post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//请求拦截器
instance.interceptors.request.use(config => {
    config.headers['token'] = sessionStorage.getItem('token');
    // try{
    //     let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    // }catch(e){
    //     console.log(e)
    // }
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const Token=localStorage.getItem('Token')
    // Token && (config.headers.Token = Token);

    // const token = store.state.token;
    // token && (config.headers.Authorization = token);
    // window.console.log(Token)
    return config;
}, error => {
    return Promise.error(error);
})

//响应拦截器
instance.interceptors.response.use(res => {
    // 对响应数据做点什么，允许在数据返回客户端前，修改响应的数据


    if (res.status === 200) {
        return Promise.resolve(res.data);
    }
    if(res.status === 201){
        window.location.href=res.data.data.ScanCodeUrl
    }
}, error => {

    // 对响应错误做点什么

    if(error.response.data.code===10000){
        sessionStorage.removeItem('token');
        window.location.reload()
    }else if(error.response.data.code===401){
        sessionStorage.removeItem('token');
        if(sessionStorage.getItem('is_wx3')==='true'){
            window.location.href=error.response.data.data.ScanCodeUrl
        }else{
            router.replace('/login').then(_=>{
                sessionStorage.clear()
                console.log('expired')
            })
        }
    }else if(error.response.data.code===20000 || error.response.data.code===20001 || error.response.data.code===20002){
        sessionStorage.setItem('expireMsg',JSON.stringify(error.response.data.data))
        console.log('gggggggggggggggggggggggggggggggggggggggggggg')
        store.dispatch('getExpireMsg',1)
        if(error.response.data.data&&error.response.data.data.kefu_qr){
            store.commit('setKF',error.response.data.data.kefu_qr)
        }
    }
    else{
        Message({
            message: error.response.data.message ? error.response.data.message : '获取数据失败，请稍后再试',
            type: 'warning'
        });
    }

    return Promise.reject(error);

})

export default instance;