<template>
  <div class="login">
    <img src="@/assets/images/left.png" class="left-bot" />
    <img src="@/assets/images/right.png" class="right-bot" />
    <img src="@/assets/images/top.png" class="right-top" />
    <div class="head">
      <img src="@/assets/images/copylogo.png" class="logo-img" />
      <h3 class="name">名冠天下</h3>
      <span class="type">企微自定义工作台</span>
    </div>
    <div class="main">
      <div class="bgblue">
        <img src="@/assets/images/leftlogo.png" class="left-logo" />
        <div class="system">名冠天下企微自定义工作台</div>
        <img src="@/assets/images/bot-logo.png" class="bot-logo" />
      </div>
      <div class="login-box">
        <div class="main-box">
          <div class="flex-end login-type" v-show="loginType===1">
            <el-tooltip placement="left" effect="light">
              <div slot="content" class="flex-start">
                <img src="@/assets/images/qweixin.png" class="qw-icon" />
                <span>扫码登陆</span>
              </div>
              <img
                src="@/assets/images/saoma.png"
                alt
                class="scan-code"
                @click="changeLoginType(2)"
              />
            </el-tooltip>
          </div>
          <el-form
            v-show="loginType===1"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-form-inline"
            size="mini"
          >
            <el-form-item prop="userid">
              <div class="label-name">账号</div>
              <el-input v-model="ruleForm.userid" placeholder="请输入您的用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <div class="label-name">密码</div>
              <el-input
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
                placeholder="请输入您的密码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="captcha" class="get-capt">
              <el-input
                v-model="ruleForm.captcha"
                autocomplete="off"
                @keyup.enter.native="onSubmit('ruleForm')"
                placeholder="请输入验证码"
              ></el-input>
              <div class="right-capt">
                <img
                  :src="'/getcaptcha'"
                  ref="captcha"
                  style="width: 154px;height: 39px;"
                  @click="refresh"
                />
                <!-- <img :src="'/api/getcaptcha'" ref="captcha" alt="" style="width: 154px;height: 38px;" @click="refresh"> -->
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit('ruleForm')">登录</el-button>
            </el-form-item>
          </el-form>
          <div class="flex-end login-type2" v-show="loginType===2">
            <el-tooltip placement="left" effect="light">
              <div slot="content" class="flex-start">
                <span>账号登陆</span>
              </div>
              <img
                src="@/assets/images/acount.png"
                alt
                class="scan-code"
                @click="changeLoginType(1)"
              />
            </el-tooltip>
          </div>
          <div class="flex-cent-col iframe-page" v-show="loginType===2">
            <div id="wx_reg"></div>
            <!--            <div>请使用企业微信扫描二维码登录</div>-->
            <!--            <div>“企微自定义工作台”</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginData, signIn } from "@/api/workbench";
export default {
  data() {
    return {
      ruleForm: {
        password: "",
        userid: "",
        captcha: ""
      },
      captchaImg: null,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        userid: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
      loginType: 2
    };
  },
  created() {
    this.getLogin();
  },
  mounted() {},
  methods: {
    getLogin() {
      getLoginData().then(res => {
        let { state, agentid, appid, redirect_uri } = res.data;
        window.WwLogin({
          id: "wx_reg",
          appid: appid,
          agentid: parseInt(agentid),
          redirect_uri: redirect_uri,
          state: state,
          href: ""
        });
      });
    },
    refresh() {
      this.$refs.captcha.src =
        location.origin + "/getcaptcha?" + new Date().getTime();
    },
    changeLoginType(type) {
      this.loginType = type;
    },
    onSubmit(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          signIn(this.ruleForm).then(res => {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userInfo");
            let { role, name, token, userId, userIds } = res.data;
            const userInfo = {
              userId: userId,
              username: name,
              company: res.data.app.corp_name,
              role: role
            };
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            window.location.href =
              location.origin + location.pathname + "#/home/index";
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login {
  background: #f3f4f5;
  width: 100%;
  height: 100%;
  position: relative;
  .left-bot {
    position: absolute;
    height: 73%;
    bottom: 0;
    left: 0;
  }
  .right-top {
    position: absolute;
    height: 58%;
    top: 0;
    right: 0;
  }
  .right-bot {
    position: absolute;
    height: 58%;
    bottom: 0;
    right: 0;
  }
  .head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 21px 40px;
    .name {
      font-size: 19px;
      color: #0082ef;
      border-right: 1px solid #b2b2b2;
      padding: 0 15px 0 7px;
    }
    .type {
      font-size: 16px;
      margin-left: 8px;
      color: #0082ef;
    }
    .logo-img {
      width: 28px;
    }
  }
  .main {
    width: 800px;
    height: 450px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    margin-top: -225px;
    left: 50%;
    margin-left: -400px;
    display: flex;
    justify-content: flex-start;
    .bgblue {
      width: 342px;
      height: 450px;
      background: linear-gradient(
        360deg,
        rgba(0, 130, 239, 1) 0%,
        rgba(0, 139, 255, 1) 100%
      );
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .left-logo {
        width: 25%;
      }
      .system {
        font-size: 14px;
        color: #fff;
        margin: 15px 0 30px;
      }
      .bot-logo {
        width: 65%;
      }
    }
    .login-box {
      width: 457px;
      background: #fff;
      .main-box {
      }
      .login-type {
        margin: 23px 30px;
        .count {
          font-size: 20px;
        }
        .scan-code {
          cursor: pointer;
        }
      }
      .login-type2 {
        float: right;
        padding-right: 20px;
        padding-top: 20px;
      }
      .iframe-page {
        padding-top: 35px;
      }

      /deep/.el-form {
        margin: 40px auto;
        width: 300px;
        .el-form-item {
          .label-name {
            font-size: 12px;
            color: #2d3034;
          }
          &.get-capt {
            .el-form-item__content {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
            }
            .right-capt {
              width: 160px;
              height: 40px;
              margin-left: 20px;
              border: 1px solid #ccc;
            }
          }
          .el-form-item__content {
            .el-button {
              width: 100%;
              margin-top: 20px;
            }
            .el-input {
              width: 100%;
              outline: none;
              border-bottom: 1px solid #b2b2b2;
              .el-input__inner {
                border: 0;
                padding: 0;
              }
            }
          }
        }
      }
      .bb-line {
        width: 208px;
        margin: 40px auto 0;
        position: relative;
        border-bottom: 1px solid #b2b2b2;
        .scale {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -65px;
          background: #fff;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          .qw-icon {
            width: 17px;
            height: 15px;
            margin-right: 4px;
          }
          .scale-login {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>