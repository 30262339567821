<template>
  <ww-open-data :type="type" :openid="openid" ref="wwOpenData"/>
</template>
<script>
import {otherBrowser, wxAgentConfig, wxConfig} from "../utils/wx";
import {getConfigApi} from "../api/config";

export default {
  name: "wxComonent",
  props: ['type', 'openid'],
  data() {
    return {
      WWOpenData: window?.WWOpenData,

    }

  },
  watch: {
    vxState() {
      this.WWOpenData?.bind?.(this.$el);
    }
  },
  created() {

  },
  mounted(){
    this.WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
  },
  computed: {
    vxState() {
      return this.$store.state.vxState
    }
  },

}
</script>