import $http from "@/utils/request.js";
import qs from "qs";


//首页
//账号登录获取二维码
export function signIn(data){
    return $http.post('/signIn',qs.stringify(data))
}

//获取token【自建】
export function getTokenZ(data){
    return $http.post('/getToken',qs.stringify(data))
}

//二维码 实例化参数
export function getLoginData(data){
    return $http.get('/getLoginData',{params:data})
}

// 登录
export function Login(data){
    return $http.get('/login',{params:data})
}

//登录权限
export function getUserAuth(data){
    return $http.post('/role/user_auth',qs.stringify(data))
}

// 首页获取模板列表
export function indexGetList(data){
    return $http.post('/template/indexSelect',qs.stringify(data))
}

// 获取扫码登陆链接
export function logOut(data){
    return $http.post('/getScanCodeUrl',qs.stringify(data))
}

// 获取客户安装应用信息
export function getUserInfo(data){
    return $http.post('/getAppInfo',qs.stringify(data))
}

//上传图片到本地【自建】
export function ziUpload(data){
    return $http.post('/template/uploadImgLocation',qs.stringify(data))
}


//---------------------------应用管理----------------------------
//获取应用列表
export function appLists(data){
    return $http.post('/app/select',qs.stringify(data))
}

//应用列表
export function getAppList(data){
    return $http.post('/app/applist',qs.stringify(data))
}

//删除应用
export function delApp(data){
    return $http.post('/app/destroy',qs.stringify(data))
}

//编辑应用
export function updateApp(data){
    return $http.post('/app/update',qs.stringify(data))
}

//新增应用
export function insertApp(data){
    return $http.post('/app/insert',qs.stringify(data))
}
//获取通讯录secret
export function getLinkSecret(data){
    return $http.post('/getLinkSecret',qs.stringify(data))
}
//编辑应用时获取应用信息
export function getAppInfo(data){
    return $http.post('/app/info',qs.stringify(data))
}

//修改设置工作台自定义开关事件推送
export function setApp(data){
    return $http.post('/app/set_application',qs.stringify(data))
}
//录入通讯录secret
export function addLinkSecret(data){
    return $http.post('/addLinkSecret',qs.stringify(data))
}
//-----------------------------数据源-----------------------------

//新增数据源
export function addSource(data){
    return $http.post('/source/insert',qs.stringify(data))
}

// 编辑数据源
export function editSource(data){
    return $http.post('/source/update',qs.stringify(data))
}

//数据源列表
export function sourceList(data){
    return $http.post('/source/select',qs.stringify(data))
}

//数据源详情
export function apiDetail(data){
    return $http.post('/source/apiinfo',qs.stringify(data))
}

//数据源列表2
export function sourceLists(data){
    return $http.post('/getSystemModular2',qs.stringify(data))
    // return $http.post('/getInitWebviewByType',qs.stringify(data))
}
//获取数据源标签
export function getApiTagList(data){
    return $http.post('/getApiTagList',qs.stringify(data))
    // return $http.post('/getInitWebviewByType',qs.stringify(data))
}
//数据源列表3
export function getApilist(data){
    return $http.post('/source/apilist',qs.stringify(data))
}

//其他用户id属性表 2
export function otherIdList(data){
    return $http.post('/other/list',qs.stringify(data))
}

//新增时校验数据源的有效性
export function checkApi(data){
    return $http.post('/source/checkAPI',qs.stringify(data))
}

//删除数据源
export function delApi(data){
    return $http.post('/source/destroy',qs.stringify(data))
}

//获取企微审批参数
export function SettingApi(){
    return $http.post('/getQwspSetting')
}

// 设置企微审批参数
export function qwspSettingApi(data){
    return $http.post('/qwspSetting',qs.stringify(data))
}

// 获取企微审批对接方式
export function getSpIsCallback(){
    return $http.post('/getSpIsCallback')
}

// 设置企微审批对接方式
export function setSpIsCallback(data){
    return $http.post('/setSpIsCallback',qs.stringify(data))
}

// 获取已经拉取审批的时间段
export function pullDate(){
    return $http.post('/getQwspPullTime')
}

// 拉取企微审批
export function pullQwsp(data){
    return $http.post('/pullQwsp',qs.stringify(data))
}

//----------------------------组织架构----------------------------

//获取子部门
export function getDepart(data){
    return $http.post('/getDepartByPid',qs.stringify(data))
}

//获取部门下人员
export function getUser(data){
    return $http.post('/getUserByDep',qs.stringify(data))
}

//设置人员角色
export function setUserRole(data){
    return $http.post('/setUserRole',qs.stringify(data))
}

//同步组织架构
export function departSync(data){
    return $http.post('/handleSync',qs.stringify(data))
}

// //获取通讯录secret
// export function getLinkSecret(data){
//     return $http.post('/getLinkSecret',qs.stringify(data))
// }


//导出人员信息文件
export function exportUser(data){
    return $http.post('/exportUser',qs.stringify(data))
}

//导出人员信息文件[自建]
export function exportUserZj(data){
    return $http.post('/exportUserForZj',qs.stringify(data))
}

//下载列表[自建]
export function downLoadZj(data){
    return $http.post('/getTaskDownloadList',qs.stringify(data))
}

//获取异步任务结果
export function getExportId(data){
    return $http.post('/getIdTranslateResult',qs.stringify(data))
}

//上传导入系统人员关系表
export function uploadXls(data){
    return $http.post('/importUserRelations',qs.stringify(data))
}

//
export function userRelation(data){
    return $http.post('/getUserDetails',qs.stringify(data))
}

//获取异步任务列表
export function getTaskList(data){
    return $http.post('/getTaskList',qs.stringify(data))
}

//
export function getTaskForZJ(data){
    return $http.post('/getTaskForZJ',qs.stringify(data))
}

//获取异步任务详情
export function getTaskDetail(data){
    return $http.post('/getTaskDetail',qs.stringify(data))
}

//编辑人员关联关系
export function updateOtherUser(data){
    return $http.post('/editOtherUser',qs.stringify(data))
}

//用于回显查人
export function checkDeptUser(data){
    return $http.post('/template/userOrDepartment',qs.stringify(data))
}

//自建应用时检测正在进行的任务的个数
export function isTasking(){
    return $http.get('/isTaskIng')
}



//-------------------------操作日志-----------------------------
export function getlstPage(data){
    return $http.post('/journal/lstPage',qs.stringify(data))
}


//-------------------------角色权限-----------------------------

//新增角色
export function addRole(data){
    return $http.post('/role/insert',qs.stringify(data))
}

//编辑角色
export function editRole(data){
    return $http.post('/role/update',qs.stringify(data))
}

//角色列表
export function roleList(data){
    return $http.post('/role/rolelist',qs.stringify(data))
}

//新增权限
export function addAuth(data){
    return $http.post('/auth/insert',qs.stringify(data))
}

//编辑权限
export function editAuth(data){
    return $http.post('/role/setauth',qs.stringify(data))
}

// //权限列表
// export function authList(data){
//     return $http.post('/auth/select',qs.stringify(data))
// }

//权限列表
export function authLists(data){
    return $http.post('/auth/authlist',qs.stringify(data))
}

// 删除角色
export function delRole(data){
    return $http.post('/role/destroy',qs.stringify(data))
}

// 删除权限
// export function delAuth(data){
//     return $http.post('/auth/destroy',qs.stringify(data))
// }


//------------------------------模板------------------------------

//新建模板
export function newModel(data){
    return $http.post('/template/insert',qs.stringify(data))
}

//模板详情
export function modelDetail(data){
    return $http.post('/template/tempinfo',qs.stringify(data))
}

//编辑模板
export function editModel(data){
    return $http.post('/template/update',qs.stringify(data))
}

//逻辑删除模板
export function delModel(data){
    return $http.post('/template/destroy',qs.stringify(data))
}

//彻底删除模板
export function deletes(data){
    return $http.post('/template/deletes',qs.stringify(data))
}

// 恢复逻辑删除的模板
export function recoveryModel(data){
    return $http.post('/template/recovery',qs.stringify(data))
}

//查询模板列表
export function getModelList(data){
    return $http.post('/template/select',qs.stringify(data))
}

//启用模板
export function useModel(data){
    return $http.post('/template/enables',qs.stringify(data))
}

//关闭模板
export function closeModel(data){
    return $http.post('/template/close',qs.stringify(data))
}

// WebView模板列表
export function webModelList(data){
    return $http.post('/webtemplate/wvlist',qs.stringify(data))
}

//编辑WebView模板
export function updataWebModel(data){
    return $http.post('/webtemplate/update',qs.stringify(data))
}

//查看某个webview模板
export function checkInfoModel(data){
    return $http.post('/webtemplate/info',qs.stringify(data))
}

// 新增WebView模板
export function insertModel(data){
    return $http.post('/webtemplate/insert',qs.stringify(data))
}
// 获取应用webview的最近设置数据
export function getAgentLastWebview(data){
    return $http.post('/getAgentLastWebview',qs.stringify(data))
}
//新闻公告绑定接口
export function setinitGetNewsList(data){
    return $http.post('/initGetNewsList',qs.stringify(data))
}
//轻松任务绑定接口
export function setbindTask(data){
    return $http.post('/bindTask',qs.stringify(data))
}

// 设置绑定企业微信账号
export function setOtherRelation(data){
    return $http.post('/setOtherRelation',qs.stringify(data))
}
//删除关联关系
export function delRelation(data){
    return $http.post('/delRelation',qs.stringify(data))
}
//更新工作台
export function updateworkbench(data){
    return $http.post('/specialDealOneUser',qs.stringify(data))
}
//是否显示更新工作台按钮
export function checkworkbench(data){
    return $http.post('/checkDealType',qs.stringify(data))
}