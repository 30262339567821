import $http from "@/utils/request.js";
import qs from "qs";


// 获取是否是自建
export function isWx3(data){
    return $http.post('/systems/iswx3',qs.stringify(data))
}


// 获取corpid和secert【自建】
export function getSecret(data){
    return $http.post('/systems/index',qs.stringify(data))
}

//添加或编辑系统参数
export function editSecret(data){
    return $http.post('/systems/setting',qs.stringify(data))
}