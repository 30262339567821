<template>
    <div>
        <el-dialog title="联系客服" :visible.sync="_show" @close="_show=false" class="Service_main" width="800px">
            <div class="top" ></div>
            <div class="bottom"></div>
            <div class="Service_all">
               <div class="imgbox">
                    <img :src="kf_qr||require('@/assets/images/kfCode.png')" alt="">
               </div>
                <h4>
                    <img src="@/assets/images/lxkf.svg" alt="">
                    客服电话：4000-2828-80
                </h4>
                <p>感谢您对我们的信任和支持，如果系统有BUG，或者您有好的功能建议，欢迎反馈！</p>
                <p>我们会及时进行处理，对系统进行优化和升级，让您有更好的使用体验！</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["show"],
    computed:{
        _show:{ get:function(){ return this.show },set:function(val){ this.$emit('update:show',val) } },
        kf_qr(){
            return this.$store.state.kfurl||JSON.parse(sessionStorage.getItem('appInfo')).kefu_qr
        }
    },
    data() {
        return {
            
        }
    },
}
</script>

<style lang="less" scoped>
    .Service_main{
        /deep/.el-dialog__header{background: #f5f5f5}

        /deep/.el-dialog__body{padding: 0;position: relative;
            .top{background:url('../assets/images/bgImage.png');background-repeat:no-repeat;background-size:100% 106%;height: 303px;}
            .bottom{background: #fff;height: 321px;}
            .Service_all{position: absolute;top: 0;bottom: 0;left: 0;right: 0;text-align: center;
                >.imgbox{width: 262px;height: 262px;display: inline-block;margin: 72px 0 0;padding: 15px;
                box-sizing: border-box;
                background: #fff;
                > img{
                    width: 100%;
                    height: 100%;
                }
                }
                h4{margin: 28px 0 40px;font-size: 24px;font-weight: bold;display: flex;align-items: center;justify-content:center;color: #333;
                    >img{display: inline-block;width: 26.84px;margin: 0 8px;}
                }
                p+p{margin: 5px 0 0 0;}
                p{font-size: 16px;font-weight: 400;color: #464646;}
            }
        }
    }
</style>
