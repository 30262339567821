<template>
  <div class="footer">
<!--    <div class="link flex-bet">-->
<!--      <div>联系我们 | </div>-->
<!--      <div>使用文档 | </div>-->
<!--      <div>更新日志 | </div>-->
<!--      <div>隐私政策 | </div>-->
<!--      <div>功能建议 | </div>-->
<!--      <div>BUG反馈</div>-->
<!--    </div>-->
<!--    <div class="flex-around">-->
<!--      <div>客服电话：4000282880</div>-->
<!--      <div>|</div>-->
<!--      <div>客服QQ：4000282880</div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="less" scoped>
.footer {
  width:340px;
  font-size: 12px;
  color: #999;
  line-height: 26px;
  margin:14px auto;
}
</style>