import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoginPage from '../views/Login.vue'
import {Login, getUserAuth,getTokenZ} from '@/api/workbench'
import {isWx3} from '@/api/iswx3'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login' ,
        component: LoginPage,
        meta: {title:'登录'},
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title:'企微自定义工作台'},
        redirect: '/home/index',
        children: [
            {
                path: '/home/index',
                name: 'index',
                meta: {index: false,
                    setting_center:false,
                    diy_workbench:false,
                    edit_template:false,
                    use_template:false,
                    del_template:false,
                    add_template:false,
                    title:'企微自定义工作台'},
                component: () => import('../views/Index/Index.vue')
            },
            {
                path: '/home/workbench',
                name: 'workbench',
                meta: {
                    use_template: false,
                    del_template: false,
                    edit_template: false,
                    add_template: false,
                    title:'自定义工作台',
                },
                component: () => import('../views/Workbench/Workbench.vue')
            },
            {
                path: '/home/datamana',
                name: 'datamana',
                redirect: '/home/datamana/index',
                component: () => import('../views/DataMana/DataManaIndex.vue'),
                children: [
                    {
                        path: '/home/datamana/index',
                        name: 'dataindex',
                        meta: {
                            data_source_mannger: false,
                            add_datasource: false,
                            edit_datasource: false,
                            del_datasource: false,
                            title:'数据源管理'
                        },
                        component: () => import('../views/DataMana/DataManage.vue')
                    },
                    {
                        path: '/home/datamana/dateil',
                        name: 'datadatail',
                        meta: {title:'数据源详情'},
                        component: () => import('../views/DataMana/DataDetail.vue')
                    },
                    {
                        path: '/home/datamana/datapull',
                        name: 'datapull',
                        meta: {title:'参数设置'},
                        component: () => import('../views/DataMana/DataPull.vue')
                    },
                    {
                        path: '/home/datamana/employeeSettings',
                        name: 'employeeSettings',
                        meta: {title:'参数设置'},
                        component: () => import('../views/DataMana/EmployeeSettings.vue')
                    },
                ]
            },
            {
                path: '/home/appmana',
                name: 'appmana',
                redirect: '/home/appmana/index',
                component: () => import('../views/AppMana/App.vue'),
                children: [
                    {
                        path: '/home/appmana/index',
                        name: 'appindex',
                        meta: {
                            application_mannger: false,
                            del_application: false,
                            edit_application: false,
                            add_application: false,
                            title:'应用管理'
                        },
                        component: () => import('../views/AppMana/AppMana.vue')
                    },
                    {
                        path: '/home/appmana/detail',
                        name: 'appdetail',
                        meta: {title:'应用详情'},
                        component: () => import('../views/AppMana/AppDetail.vue')
                    },
                ]
            },
            {
                path: '/home/dept',
                name: 'dept',
                meta: {

                    import_user_relation: false,
                    synchronization_organizational: false,
                    look_organizational: false,
                    edit_user:false,
                    title:'组织架构'
                },
                component: () => import('../views/Department/Depart.vue')
            },
            {
                path: '/home/set',
                name: 'set',
                redirect: '/home/set/asynchronous',
                component: () => import('../views/Setting/Set.vue'),
                children: [
                    {
                        path: '/home/set/asynchronous',
                        name: 'asynchronous',
                        meta: {title:'任务列表'},
                        component: () => import('../views/Setting/Asynchronous.vue')
                    },
                    {
                        path: '/home/set/index',
                        name: 'setindex',
                        meta: {add_role: false,
                            role_auth_set: false,
                            edit_role: false,
                            del_role: false,
                            look_authority:false,
                            title:'角色权限设置'},
                        component: () => import('../views/Setting/role.vue')
                    },
                    {
                        path: '/home/set/param',
                        name: 'param',
                        meta: {title:'参数设置'},
                        component: () => import('../views/Setting/Param.vue')
                    },
                    {
                        path: '/home/set/operation',
                        name: 'Operation',
                        meta: {title:'操作日志'},
                        component: () => import('../views/Setting/Operation.vue')
                    },
                ]
            },
        ]
    },
    {
        path: '/home/model',
        name: 'model',
        meta:{title:'模板'},
        component: () => import('../views/Workbench/Model.vue')
    },
    {
        path: '/Staffing',
        name: 'Staffing',
        meta:{title:'员工服务'},
        component: () => import('../views/mobile/Staffing.vue')
    },
    {
        path: '/pick',
        name: 'pickhtml',
        meta:{title:'员工服务'},
        component: () => import('../views/mobile/pick.vue')
    },
    {
        path: '/mobile',
        name: 'mobile',
        meta:{},
        component: () => import('../views/mobile/index.vue')
    },
    {
        path: '/carousel',
        name: 'webview',
        meta:{},
        component: () => import('../views/mobile/webView.vue')
    },
    {
        path: '/list',
        name: 'list',
        meta:{},
        component: () => import('../views/mobile/list.vue')
    },

]

const router = new VueRouter({
    routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
if (process.env.NODE_ENV === 'production') {
    router.beforeEach((to, from, next) => {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        // //woguyide,chongxieba
        // if (location.hash.indexOf('#/list')==-1 && location.hash.indexOf('#/carousel')==-1) {
        //     if (to.path == '/mobile') {
        //         next();
        //     } else {
        //         if (to.path != '/Staffing' && to.path != '/pick') {
        //             next({path: '/mobile'})
        //             return ;
        //         }
        //         next()
        //     }
        // }else 
        if(location.hash.indexOf('#/list')>-1 || location.hash.indexOf('#/carousel')>-1){
            next()
        } else{
            function GetQueryString(name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
                var r = window.location.search.substr(1).match(reg);
                if (r != null) return unescape(r[2]);
                return null;
            }
            let getToken = GetQueryString('token')
            let allIsWx3=false;
            if (/MicroMessenger/i.test(navigator.userAgent)){//企业微信
                let getUserId=null;let getFrom=null;
                if(!sessionStorage.getItem('loginType')){
                    getFrom=getCookie('logintype')
                    sessionStorage.setItem('loginType',getFrom)
                }else{
                    getFrom=sessionStorage.getItem('logintype')
                }
                if(!sessionStorage.getItem('userid')){
                    getUserId=getCookie('userid')
                    sessionStorage.setItem('userid',getUserId)
                }else{
                    getUserId=sessionStorage.getItem('userid')
                }
                if(!sessionStorage.getItem('is_wx3')){
                    isWx3().then(res=>{
                        sessionStorage.setItem('is_wx3',res?.data?.is_wx3)
                        allIsWx3=res?.data?.is_wx3
                        if (getToken) {
                            sessionStorage.setItem("loginToken", getToken);
                            if (!sessionStorage.getItem('token')) {
                                goLogin(getToken).then(_=>{
                                    const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                    if(role_id===1){
                                        store.dispatch('InitListData','all')
                                    }else{
                                        getUserAuth({role_id: role_id}).then(res => {
                                            store.dispatch('InitListData',res.data);
                                            sessionStorage.setItem('auth',JSON.stringify(res.data))

                                        })
                                    }
                                    if(flag){
                                        if(to.path == '/mobile'){
                                            next()
                                        }else{
                                            next('/mobile');
                                        }
                                    }else{
                                        next()
                                    }
                                })
                            }else{
                                const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                if(role_id===1){
                                    store.dispatch('InitListData','all');
                                }else{
                                    let auth=sessionStorage.getItem('auth')
                                    if(!auth){
                                        getUserAuth({role_id: role_id}).then(res => {
                                            store.dispatch('InitListData',res.data)
                                            sessionStorage.setItem('auth',JSON.stringify(res.data))
                                        })
                                    }else{
                                        store.dispatch('InitListData',JSON.parse(auth))
                                    }
                                }
                            }
                            next()
                        } else {
                            getToken = sessionStorage.getItem("loginToken");
                            if (!sessionStorage.getItem('token')) {
                                if(getToken){
                                    goLogin(getToken).then(_=>{
                                        const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                        if(role_id===1){
                                            store.dispatch('InitListData','all')
                                        }else{
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                            })
                                        }
                                        if(flag){
                                            if(to.path == '/mobile'){
                                                next()
                                            }else{
                                                next('/mobile');
                                            }
                                        }else{
                                            next()
                                        }
                                    })
                                }else{
                                    getTokenZiJian(getUserId).then(_=>{
                                        const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                        if(role_id===1){
                                            store.dispatch('InitListData','all')
                                        }else{
                                            let auth=sessionStorage.getItem('auth')
                                            if(!auth){
                                                getUserAuth({role_id: role_id}).then(res => {
                                                    store.dispatch('InitListData',res.data)
                                                    sessionStorage.setItem('auth',JSON.stringify(res.data))
                                                })
                                            }else{
                                                store.dispatch('InitListData',JSON.parse(auth))
                                            }
                                        }
                                    })
                                }

                            }else{
                                const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                if(role_id===1){
                                    store.dispatch('InitListData','all');
                                }else{
                                    let auth=sessionStorage.getItem('auth')
                                    if(!auth){
                                        getUserAuth({role_id: role_id}).then(res => {
                                            store.dispatch('InitListData',res.data)
                                            sessionStorage.setItem('auth',JSON.stringify(res.data))
                                        })
                                    }else{
                                        store.dispatch('InitListData',JSON.parse(auth))
                                    }
                                }
                            }
                            next()
                        }

                    })
                }else{
                    allIsWx3=sessionStorage.getItem("is_wx3")
                    const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                    if(role_id===1){
                        store.dispatch('InitListData','all');
                    }else{
                        let auth=sessionStorage.getItem('auth')
                        if(!auth){
                            getUserAuth({role_id: role_id}).then(res => {
                                store.dispatch('InitListData',res.data)
                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                            })
                        }else{
                            store.dispatch('InitListData',JSON.parse(auth))
                        }
                    }
                }

                next()
            }else{//浏览器
                console.log(sessionStorage.getItem('is_wx3'),'123')
                if(!sessionStorage.getItem('is_wx3')){
                    isWx3().then(res=>{
                        sessionStorage.setItem('is_wx3',res?.data?.is_wx3)
                        allIsWx3=res?.data?.is_wx3
                        if(!allIsWx3){//自建
                            if (to.path === '/login') {
                                next();
                            } else {
                                next({path: '/login'})
                            }
                        }else{
                            if (getToken) {
                                sessionStorage.setItem("loginToken", getToken);
                                if (!sessionStorage.getItem('token')) {
                                    goLogin(getToken).then(_=>{
                                        const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                        if(role_id===1){
                                            store.dispatch('InitListData','all')
                                        }else{
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data);
                                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                                            })
                                        }
                                        if(flag){
                                            if(to.path == '/mobile'){
                                                next()
                                            }else{
                                                next('/mobile');
                                            }
                                        }else{
                                            next()
                                        }
                                    })
                                }else{
                                    const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                    if(role_id===1){
                                        store.dispatch('InitListData','all');
                                    }else{
                                        let auth=sessionStorage.getItem('auth')
                                        if(!auth){
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                                            })
                                        }else{
                                            store.dispatch('InitListData',JSON.parse(auth))
                                        }
                                    }
                                }
                                next()
                            } else {
                                getToken = sessionStorage.getItem("loginToken");
                                if (!sessionStorage.getItem('token')) {
                                    goLogin(getToken).then(_=>{
                                        const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                        if(role_id===1){
                                            store.dispatch('InitListData','all')
                                        }else{
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                            })
                                        }
                                        if(flag){
                                            if(to.path == '/mobile'){
                                                next()
                                            }else{
                                                next('/mobile');
                                            }
                                        }else{
                                            next()
                                        }
                                    })
                                }else{
                                    const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                    if(role_id===1){
                                        store.dispatch('InitListData','all');
                                    }else{
                                        let auth=sessionStorage.getItem('auth')
                                        if(!auth){
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                                            })
                                        }else{
                                            store.dispatch('InitListData',JSON.parse(auth))
                                        }
                                    }
                                }
                                next()
                            }
                        }
                    })
                    next()
                }else{
                    allIsWx3=sessionStorage.getItem("is_wx3")==='true';
                    if(location.hash.indexOf('/login')>-1){
                        if(sessionStorage.getItem('token')){
                            next('/home/index')
                        }
                    }

                    if(!sessionStorage.getItem('token')){
                        if(allIsWx3){
                            if (getToken) {
                                sessionStorage.setItem("loginToken", getToken);
                                if (!sessionStorage.getItem('token')) {
                                    goLogin(getToken).then(_=>{
                                        const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                        if(role_id===1){
                                            store.dispatch('InitListData','all')
                                        }else{
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data);
                                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                                            })
                                        }
                                        if(flag){
                                            if(to.path == '/mobile'){
                                                next()
                                            }else{
                                                next('/mobile');
                                            }
                                        }else{
                                            next()
                                        }
                                    })
                                }else{
                                    const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                    if(role_id===1){
                                        store.dispatch('InitListData','all');
                                    }else{
                                        let auth=sessionStorage.getItem('auth')
                                        if(!auth){
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                                            })
                                        }else{
                                            store.dispatch('InitListData',JSON.parse(auth))
                                        }
                                    }
                                }
                                next()
                            } else {
                                getToken = sessionStorage.getItem("loginToken");
                                if (!sessionStorage.getItem('token')) {
                                    goLogin(getToken).then(_=>{
                                        const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                        if(role_id===1){
                                            store.dispatch('InitListData','all')
                                        }else{
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                            })
                                        }
                                        if(flag){
                                            if(to.path == '/mobile'){
                                                next()
                                            }else{
                                                next('/mobile');
                                            }
                                        }else{
                                            next()
                                        }
                                    })
                                }else{
                                    const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                    if(role_id===1){
                                        store.dispatch('InitListData','all');
                                    }else{
                                        let auth=sessionStorage.getItem('auth')
                                        if(!auth){
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                                            })
                                        }else{
                                            store.dispatch('InitListData',JSON.parse(auth))
                                        }
                                    }
                                }
                                next()
                            }
                        }else{
                            let getFrom=getCookie('logintype');
                            let getUserId=getCookie('userid')
                            if(getFrom=='scan'){
                                getTokenZiJian(getUserId).then(_=>{
                                    const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                                    if(role_id===1){
                                        store.dispatch('InitListData','all')
                                    }else{
                                        let auth=sessionStorage.getItem('auth')
                                        if(!auth){
                                            getUserAuth({role_id: role_id}).then(res => {
                                                store.dispatch('InitListData',res.data)
                                                sessionStorage.setItem('auth',JSON.stringify(res.data))
                                            })
                                        }else{
                                            store.dispatch('InitListData',JSON.parse(auth))
                                        }
                                    }
                                })
                            }
                        }

                    }else{
                        const role_id=parseInt(JSON.parse(sessionStorage.getItem('userInfo')).role);
                        if(role_id===1){
                            store.dispatch('InitListData','all');
                        }else{
                            let auth=sessionStorage.getItem('auth')
                            if(!auth){
                                getUserAuth({role_id: role_id}).then(res => {
                                    store.dispatch('InitListData',res.data)
                                    sessionStorage.setItem('auth',JSON.stringify(res.data))
                                })
                            }else{
                                store.dispatch('InitListData',JSON.parse(auth))
                            }
                        }
                    }
                    next()
                }
            }
        }
        if(to.meta.title){
            document.title=to.meta.title
        }
            // if (getToken) {//如果有token，就可以请求login
            //     next();
            // } else {//如果没有token，就拦截
            //     if(location.hash.indexOf('#/list')>-1 || location.hash.indexOf('#/carousel')>-1){
            //         next()
            //     }else{
            //         goLogin()
            //         next()
            //     }
            // }
            // next()




        // }
    });
} else {
 
    router.beforeEach((to, from, next) => {
            
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            // console.log(to.path,'to.path')
            // if (flag && location.hash.indexOf('#/list')==-1 && location.hash.indexOf('#/carousel')==-1) {
            //     if (to.path == '/mobile') {
            //         next();
            //     } else { 
            //         if (to.path != '/Staffing' && to.path != '/pick') {
            //             next({path: '/mobile'})
            //             return ;
            //         }
            //         next()
            //     }
            // }else{
                // if(location.hash.indexOf('#/list')>-1 || location.hash.indexOf('#/carousel')>-1){
                //     next()
                // }else{
                    let allIsWx3=true;
                    if(!sessionStorage.getItem('is_wx3')){
                        isWx3().then(res=>{
                            sessionStorage.setItem('is_wx3',res?.data?.is_wx3)
                            allIsWx3=res?.data?.is_wx3
                        })
                    }else{
                        allIsWx3=sessionStorage.getItem("is_wx3")
                    }
                    if(!allIsWx3){
                        if (to.path == '/login') {
                            next();
                        } else {
                            next({path: '/login'})
                        }
                    }else{
                       
                        if (!sessionStorage.getItem('token')) {
                            goLogin().then(_=>{
                                const role_id=JSON.parse(sessionStorage.getItem('userInfo')).role
                                if(role_id==1){
                                    store.dispatch('InitListData','all')
                                }else{
                                    getUserAuth({role_id: role_id}).then(res => {
                                        store.dispatch('InitListData',res.data)
                                        sessionStorage.setItem('auth',JSON.stringify(res.data))
                                    })
                                }
                            })
                        }else{
                         
                            const role_id=JSON.parse(sessionStorage.getItem('userInfo')).role;
                           
                           
                            if(role_id==1){
                                store.dispatch('InitListData','all');
                            }else{
                                let auth=sessionStorage.getItem('auth')
                                if(!auth){
                                    getUserAuth({role_id: role_id}).then(res => {
                                        store.dispatch('InitListData',res.data)
                                        sessionStorage.setItem('auth',JSON.stringify(res.data))
                                    })
                                }else{
                                    store.dispatch('InitListData',JSON.parse(auth))
                                }
                            }
                        }
                    }

                // }

                if(to.meta.title){
                    document.title=to.meta.title
                }
                if (flag) {
                    if(to.path == '/mobile'){
                        next()
                    }else{
                        next('/mobile');
                    }
                    
                    // if (to.path == '/mobile') {
                        
                    // } else { 
                    //     if (to.path != '/Staffing' && to.path != '/pick') {
                    //         next({path: '/mobile'})
                    //         return ;
                    //     }
                    //     next()
                    // }
                }else{
                    next()
                }
        //     }
        // }
    })
}

function setAuthList(list) {
    return list.reduce((prev, {children = [], ...item}) => {
        return prev.concat([item], setAuthList(children))
    }, [])
}

function getCookie(name) { //获取指定名称的cookie值
// (^| )name=([^;]*)(;|$),match[0]为与整个正则表达式匹配的字符串，match[i]为正则表达式捕获数组相匹配的数组；
    var arr = document.cookie.match(new RegExp("(^| )"+name+"=([^;]*)(;|$)"));
    if(arr != null) {
        return unescape(arr[2]);
    }
    return null;
}

function letUserAuth(role_id) {
    return getUserAuth({role_id: role_id}).then(res => {
        sessionStorage.setItem('userAuth', JSON.stringify(res.data))
    })

}

function getTokenZiJian(userid){
    return getTokenZ({userid}).then(res=>{
        const userInfo = {
            userId: res.data.userId,
            username: res.data.name,
            company: res.data.app.corp_name,
            role:res.data.role,
        }
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    })
}

function goLogin(token) {
    return Login({token}).then(res => {
        const userInfo = {
            userId: res.data.userId,
            username: res.data.name,
            dept: res.data.total_department,
            totalUser: res.data.total_user,
            company: res.data.app.corp_name,
            role:res.data.role,
        }
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    }).catch(err => {
        console.log(err,'err')
    })
}


export default router
